import React from "react"

export default function MarginBlock({
  children,
  top = 0,
  right = 0,
  left = 0,
  bottom = 0,
  ...rest
}) {
  return (
    <div
      css={{
        marginLeft: left,
        marginRight: right,
        marginTop: top,
        marginBottom: bottom,
      }}
      {...rest}
    >
      {children}
    </div>
  )
}
